define("discourse/plugins/discourse-category-experts/discourse/components/modal/endorse-user/index", ["exports", "discourse/plugins/discourse-category-experts/discourse/components/modal/endorse-user/endorsement-checkboxes", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _endorsementCheckboxes, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EndorseUserModal = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <EndorsementCheckboxes
      @user={{@model.user}}
      @endorsements={{@model.endorsements}}
      @location={{@model.location}}
      @closeModal={{@closeModal}}
      @afterSave={{@closeModal}}
    />
  
  */
  {
    "id": "trMaGrKi",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@user\",\"@endorsements\",\"@location\",\"@closeModal\",\"@afterSave\"],[[30,1,[\"user\"]],[30,1,[\"endorsements\"]],[30,1,[\"location\"]],[30,2],[30,2]]],null],[1,\"\\n\"]],[\"@model\",\"@closeModal\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-experts/discourse/components/modal/endorse-user/index.js",
    "scope": () => [_endorsementCheckboxes.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = EndorseUserModal;
});