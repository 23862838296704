define("discourse/plugins/discourse-category-experts/discourse/templates/modal/endorse-user", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  */
  {
    "id": "Z8zqYoaU",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/modal/endorse-user.hbs",
    "isStrictMode": false
  });
});